import axios from 'axios';
import { Middleware, Dispatch, AnyAction, Action } from 'redux';
import showAlert from '../components/alert';
import apiResponses from '../constants/apiResponseCodes';

/* ----- For fetching the unique web token ---- */
const uniqueToken = btoa(Math.random() + navigator.userAgent + Date());

/* ----- For fetching the unique device id ---- */
const UniqueDeviceId = parseInt(
  Math.ceil(Math.random() * Date.now())
    .toPrecision()
    .toString()
    .replace('.', '')
);

console.log('gitttttttttttttt', process.env.REACT_APP_BASE_API);

//axios instance api
export const apiURLBase = process.env.REACT_APP_BASE_API
  ? process.env.REACT_APP_BASE_API
  : 'https://aesadevapi.appskeeper.in/api/v1/';

export const API = axios.create({
  baseURL: apiURLBase,
  headers: {
    devicetype: '1',
    devicetoken: uniqueToken,
    deviceid: UniqueDeviceId,
  },
});

const PUBLIC_AUTH = `Basic ${
  process.env.REACT_APP_PUBLIC_BASE_AUTH || 'YW1leGNoYXJnZTpYenlzJXtKTl53OUFzQw='
}`;

export const PUBLIC_API = axios.create({
  baseURL: apiURLBase,
  headers: {
    devicetype: '1',
    devicetoken: uniqueToken,
    deviceid: UniqueDeviceId,
    Authorization: PUBLIC_AUTH,
  },
});

// logging out the user when the token is expired
const checkTokenValidity = async (err: any) => {
  const originalRequest = err.config;

  if (err.response.status === apiResponses.preconditionFailed) {
    return new Promise(() => {
      const data = err.response.data;
      showAlert(2, data.message);
      setTimeout(() => {
        window.location.href = `${process.env.REACT_APP_SUB_DIRECTORY}`;
      }, 2500);
    });
  }

  if (
    (err.response.status === apiResponses.unauthorized ||
      err.response.status === apiResponses.userDeleteOrBlocked) &&
    err &&
    originalRequest &&
    !originalRequest._retry
  ) {
    return new Promise(() => {
      delete API.defaults.headers.common['Authorization'];
      localStorage.removeItem('accessToken');
      localStorage.removeItem('redirectTo');
      const data = err.response.data;
      showAlert(2, data.message);
      setTimeout(() => {
        window.location.reload();
      }, 2500);
    });
  }

  return Promise.reject(err);
};

/**
 * setting up middleware in order to dispatch redux actions
 */
export const apiMiddleware: Middleware = () => (next: Dispatch<AnyAction>) => (action: Action) => {
  API.interceptors.request.use(
    (config: any) => config,
    (error: any) => Promise.reject(error)
  );
  API.interceptors.response.use(
    (response: any) => response,
    // Checking whether the token is expired for the failed service
    (err: any) => checkTokenValidity(err)
  );
  next(action);
};

/**
 * setting up middleware in order to dispatch redux actions
 */
export const publicApiMiddleware: Middleware =
  () => (next: Dispatch<AnyAction>) => (action: Action) => {
    PUBLIC_API.interceptors.request.use(
      (config: any) => config,
      (error: any) => Promise.reject(error)
    );
    PUBLIC_API.interceptors.response.use(
      (response: any) => response,
      // Checking whether the token is expired for the failed service
      (err: any) => {}
    );
    next(action);
  };
