import showAlert from '../../components/alert';
import ACTION_NAMES from '../../constants/actionNames';
import ENDPOINTS from '../../constants/endpoints';
import { checkResponseStatus } from '../../helpers';
import { API } from '../../middlewares';
import { updateGlobalLoader } from '../../redux/globalActions';

export const searchCoreCaseListing = (searchValue: number) => (dispatch: any, getState: any) => {
  const { caseListSearch, caseListPage, caseListLimit } = getState().SearchInCoreReducer;

  const searchCheck = (searchBy: number) => {
    switch (searchBy) {
      case 1:
        return 'transactionId';
      case 2:
        return 'arnNumber';
      case 3:
        return 'seNumber';
      case 4:
        return 'caseId';
      default:
        return 'transactionId';
    }
  };

  let dataToSend = {};
  dataToSend = {
    page: caseListPage,
    limit: caseListLimit,
    search: caseListSearch,
    searchOn: searchCheck(searchValue),
  };
  dispatch(updateGlobalLoader(true));

  API.post(ENDPOINTS.codeCaseSearch, dataToSend)
    .then((response: any) => {
      if (checkResponseStatus(response)) {
        const { data } = response;
        dispatch({
          type: ACTION_NAMES.SEARCH_IN_CORE,
          payload: {
            caseListingData: data?.data?.data,
            caseListCount:
              data?.data?.count !== undefined ? data?.data?.count : data?.data?.data.length,
            caseListPage: data?.data?.page,
            caseListPageCount: data?.data?.pageCount,
          },
        });
      } else {
        showAlert(2, response?.data?.message);
      }
      dispatch(updateGlobalLoader(false));
    })
    .catch((error) => {
      const { response } = error;
      showAlert(2, response?.data?.message);
      dispatch(updateGlobalLoader(false));
    });
};

export const searchCoreCaseListingDetailsPage =
  (searchValue: string, searchOn: string) => (dispatch: any, getState: any) => {
    const { caseListPage, caseListLimit } = getState().SearchInCoreReducer;

    let dataToSend = {};
    dataToSend = {
      page: caseListPage,
      limit: caseListLimit,
      search: searchValue,
      searchOn: searchOn,
    };
    dispatch(updateGlobalLoader(true));

    API.post(ENDPOINTS.codeCaseSearch, dataToSend)
      .then((response: any) => {
        if (checkResponseStatus(response)) {
          const { data } = response;
          dispatch({
            type: ACTION_NAMES.SEARCH_IN_CORE,
            payload: {
              caseListingData: data?.data?.data,
              caseListCount:
                data?.data?.count !== undefined ? data?.data?.count : data?.data?.data.length,
              caseListPage: data?.data?.page,
              caseListPageCount: data?.data?.pageCount,
            },
          });
        } else {
          showAlert(2, response?.data?.message);
        }
        dispatch(updateGlobalLoader(false));
      })
      .catch((error) => {
        const { response } = error;
        showAlert(2, response?.data?.message);
        dispatch(updateGlobalLoader(false));
      });
  };

export const getCaseDetailsFromCoreSearch =
  (search: string | null, searchOn: string | null, caseType: string | null) =>
  (dispatch: any, getState: any) => {
    let dataToSend = {};
    dataToSend = {
      coreCaseId: search,
      searchOn,
    };
    dispatch(updateGlobalLoader(true));

    API.post(ENDPOINTS.searchCaseCheck, dataToSend)
      .then((response: any) => {
        if (checkResponseStatus(response)) {
          const { data } = response;
          const caseDetailsData = data?.data?.data;

          if (caseDetailsData && caseDetailsData.length > 1) {
            const reqData = caseDetailsData.filter(
              (data: any) => `${data.messageType}` === caseType

              // {
              //   if (`${data.messageType}` === caseType) {
              //     return data;
              //   }
              // }
            );

            const caseDetails = reqData[0];

            dispatch({
              type: ACTION_NAMES.CASE_INBOX,
              payload: {
                case_id: caseDetails?.id,
                case_caseId: caseDetails?.caseId,
                case_startingCardNumber: caseDetails?.startingCardMemberNumber,
                case_endingCardNumber: caseDetails?.endingCardMemberNumber,
                case_SENumber: caseDetails?.SENumber,
                case_SEName: caseDetails?.SEName,
                case_merchantCategoryCode: caseDetails?.merchantCategoryCode,
                case_retailerId: caseDetails?.retailerId,
                case_ARNNumber: caseDetails?.ARNNumber,
                case_terminalNumber: caseDetails?.terminalNumber,
                case_transactionDate: caseDetails?.transactionDate,
                case_transactionTime: caseDetails?.transactionTime,
                case_transactionAmount: caseDetails?.transactionAmount,
                case_transactionCurrency: caseDetails?.transactionCurrency,
                case_presentmentAmount: caseDetails?.presentmentAmount,
                case_transactionId: caseDetails?.transactionId,
                case_transactionType: caseDetails?.transactionType,
                case_approvalCode: caseDetails?.approvalCode,
                case_caseType: caseDetails?.caseType,
                case_sent: caseDetails?.sent,
                case_messageType: caseDetails?.messageType,
                caseDetailsComplete: caseDetails,
                caseDetailsIsValid: caseDetails?.isValid,
                case_messageReasonCode: caseDetails?.messageReasonCode,
                case_itemizedDocumentType: caseDetails?.itemizedDocumentType,
                case_coreCase_amount: caseDetails?.amount,
                isActionTaken: caseDetails?.isActionTaken,
              },
            });
          } else if (caseDetailsData && caseDetailsData[0]) {
            const caseDetails = caseDetailsData[0];

            dispatch({
              type: ACTION_NAMES.CASE_INBOX,
              payload: {
                case_id: caseDetails?.id,
                case_caseId: caseDetails?.caseId,
                case_startingCardNumber: caseDetails?.startingCardMemberNumber,
                case_endingCardNumber: caseDetails?.endingCardMemberNumber,
                case_SENumber: caseDetails?.SENumber,
                case_SEName: caseDetails?.SEName,
                case_merchantCategoryCode: caseDetails?.merchantCategoryCode,
                case_retailerId: caseDetails?.retailerId,
                case_ARNNumber: caseDetails?.ARNNumber,
                case_terminalNumber: caseDetails?.terminalNumber,
                case_transactionDate: caseDetails?.transactionDate,
                case_transactionTime: caseDetails?.transactionTime,
                case_transactionAmount: caseDetails?.transactionAmount,
                case_transactionCurrency: caseDetails?.transactionCurrency,
                case_presentmentAmount: caseDetails?.presentmentAmount,
                case_transactionId: caseDetails?.transactionId,
                case_transactionType: caseDetails?.transactionType,
                case_approvalCode: caseDetails?.approvalCode,
                case_caseType: caseDetails?.caseType,
                case_sent: caseDetails?.sent,
                case_messageType: caseDetails?.messageType,
                caseDetailsComplete: caseDetails,
                caseDetailsIsValid: caseDetails?.isValid,
                case_messageReasonCode: caseDetails?.messageReasonCode,
                case_itemizedDocumentType: caseDetails?.itemizedDocumentType,
                case_coreCase_amount: caseDetails?.amount,
                isActionTaken: caseDetails?.isActionTaken,
              },
            });
          }
        } else {
          showAlert(2, response?.data?.message);
        }
        dispatch(updateGlobalLoader(false));
      })
      .catch((error) => {
        const { response } = error;
        showAlert(2, response?.data?.message);
        dispatch(updateGlobalLoader(false));
      });
  };
